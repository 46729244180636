import { useEffect, useMemo, useState} from 'react';
// @ts-ignore
import chroma from "chroma-js";

export function useColorTransition()
{
	const root = document.documentElement;
	const [tick, setTick] = useState(0);
	const colors = useMemo(() => {
		return chroma.scale([
			'#2C7BE5',
			'#81b3e4',
			'#b399e6',
			'#e6bd4d',
			'#e87d7d',
			'#e06ca6',
			'#70dbdb',
			'#e06ca6',
			'#e87d7d',
			'#e6bd4d',
			'#b399e6',
			'#81b3e4',
		]).colors(2e3);
	}, []);

	useEffect(() => {
		const timer = window.setInterval(() => {
			setTick(tick => tick + 1);
			root.style.setProperty("--color", colors[tick % colors.length]);
		}, colors.length / 2e3);

		return () => window.clearInterval(timer); 

	}, [tick, colors]);
}