import { useState, useEffect } from 'react'


const RESET = 'RESET';
const TYPING = 'TYPING';
const BLINKING = 'BLINKING';
const CLEARING = 'CLEARING';

const BLINK_COUNT = 10;
const TYPER_SPEED = 40; // ms

type TyperOptions = {
  speed: number;
}

export function useLineStateMachine(lines: String[], options: TyperOptions = {speed: TYPER_SPEED}) {
  const [state, setState] = useState({
    mode: RESET,
    lineIndex: 0,
    tick: 0,
    text: '',
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setState(currentState => {

        const {
          mode, 
          lineIndex, 
          tick,
        } = currentState;
        
        switch (mode) {
          case RESET:
            return {
              ...currentState,
              mode: TYPING,
              lineIndex: (lineIndex + 1) % lines.length,
              tick: 0,
              text: '',
              blinks: 0,
            };

          case TYPING:
            if (tick > 2 * lines[lineIndex].length) {
              return { 
                ...currentState, 
                tick: 0,
                mode: BLINKING
              };
            } 
            
            if (tick % 2 === 0) {
              return {
                ...currentState, 
                tick: tick + 1
              }
            }
            
            return {
              ...currentState,
              text: lines[lineIndex].substring(0, Math.ceil(tick / 2)),
              tick: tick + 1,
            };

          case BLINKING:
            if (tick >= BLINK_COUNT) {
              return { 
                ...currentState, 
                tick: 0,
                mode: CLEARING
              };
            }

            return {
              ...currentState,
              tick: tick + 1,
            };
            
          case CLEARING:
            if (currentState.text.length === 0) {
              return {...currentState, mode: RESET}
            }
            
            return {
              ...currentState,
              text: currentState.text.slice(0, -1)
            };         
           

          default:
            return currentState;
        }
      });
    }, options.speed);

    return () => clearInterval(timer);
  }, []);

  return [state.text, state.mode === BLINKING];
}