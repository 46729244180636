import React, {Fragment} from 'react';
import './App.css';
import 'normalize.css';

import { useLineStateMachine } from './hooks/useLineStateMachine';
import { useColorTransition } from './hooks/useColorTransition';

import {GithubIcon, MailIcon, InstagramIcon} from './img/icons';

const lines = [
  'ist Softwarentwickler',
  'hat die Plattform Thruhiker.de entwickelt',
  'ist zufuß durch Norwegen gelaufen',
  'kann ein super Schalotten-Paprika-Gulasch kochen',
  'kann Blitzschnell Cloud-Infrastrukturen orchestrieren',
  'spielt Gitarre in einer Synth-Ambient-Kombo',
  'kann KI zum Leben erwecken',
];

type Project = {
  label: string;
  href: string;
  text: string;
}

const projects: Project[] = [
  {
    label: 'Thruhiker',
    href: 'https://next.thruhiker.de', 
    text: 'Thruhiker'
  },
  {
    label: 'VdS Webshop',
    href: 'https://shop.vds.de', 
    text: 'Regelwerke'
  },
  {
    label: 'VdS Caruso',
    href: 'https://bildung.vds.de', 
    text: 'Weiterbildung'
  },
  {
    label: 'GitHub',
    href: 'https://github.com/inceddy', 
    text: 'Open Source'
  },
];

const clients = [
  {
    label: 'VdS',
    href: 'https://vds.de', 
    text: 'VdS Schadenverhütung'
  },
  {
    label: 'E-Mediad',
    href: 'https://emediad.de', 
    text: 'E-MEDIAD'
  },
  {
    label: 'AllesGute.Live',
    href: 'https://allesgute.live',
    text: 'AllesGute.Live',
  },
  {
    label: 'Game On',
    href: 'https://www.game-on.eu', 
    text: 'GAME ON'
  },
  {
    label: 'LiveMusicHall',
    href: 'https://livemusichall.de',
    text: 'LiveMusicHall',
  },
]

function App() {

  useColorTransition();
  const [text, blinking] = useLineStateMachine(lines);
  return (
    <>
      <header className="about table">
        <div className="table-cell">
          <div className="inner clearfix">
            <div className="profile">
              <h2 className="hero-text">
                Philipp{" "}
                <span className="typer">{text}</span>
                <span className={`cursor ${blinking ? 'blinking' : ''}`}>_</span>
              </h2>
              <nav className="social">
                <ul className="inline">
                  <li>
                    <a className="social-link" data-gtm-category="Social" data-gtm-action="click" data-gtm-label="Email" href="mailto:philipp@stg-development.com">
                      <MailIcon/>
                    </a>
                  </li>
                  <li>
                    <a className="social-link" data-gtm-category="Social" data-gtm-action="click" data-gtm-label="Instagram" target="_blank" href="https://instagram.com/inceddy">
                      <InstagramIcon />
                    </a>
                  </li>
                  <li>
                    <a className="social-link" data-gtm-category="Social" data-gtm-action="click" data-gtm-label="Github" target="_blank" href="https://github.com/inceddy">
                      <GithubIcon />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="browser-height"/>
      <footer className="footer">
        <nav className="projects">
          <ul>
            {projects.map(({label, href, text}, index) => (
              <li key={index}>
                <a className="project-link" target="_blank" data-gtm-category="Project" data-gtm-action="click" data-gtm-label={label} href={href}>
                  <div className="inner inner--link">
                    <h3 className="project-highlight">{text}</h3>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="worked-with">
          <div className="inner worked-with--label">Notable Clients</div>
          <div className="inner">
            {clients.map(({label, href, text}, index) => (
                <Fragment key={index}>
                  {index > 0 && ", "}
                  <a key={index} href={href} target="_blank" data-gtm-category="Worked With" data-gtm-action="click" data-gtm-label={label}>{text}</a>
                </Fragment>
            ))}
          </div>
        </div>
        <div className="impressum">
          <div className="inner">
            <p>STG Development UG (haftungsbeschränkt), Annostr. 78, 50678 Köln, Telefon: +49 (0) 221 16 999 405, E-Mail: philipp@stg-development.com</p>
            <p>Vertreten durch: Geschäftsführer Philipp Steingrebe</p>
            <p>Registereintrag: Eingetragen im Handelsregister Registergericht: AG Köln, Registernummer: HRB 86602, USt-IdNr.: DE305427896</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
