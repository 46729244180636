export const GithubIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-github">
    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"/>
  </svg>
);

export const InstagramIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
    <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"/>
  </svg>
);

export const MailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail">
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
    <polyline points="22,6 12,13 2,6"/>
  </svg>
);

export const Logo = () => (
  <svg width="60" height="45" viewBox="0 0 278 272" fill="#fff" stroke="none" strokeWidth="0" strokeLinecap="round" strokeLinejoin="round" className="svg-logo">
    <path d="M277.52,64.79c-10.12,0-18.74,0-28.28,0c0-7.06,0.03-13.98-0.01-20.9c-0.06-12.96-9.92-20.56-22.5-17.37
      c-6.42,1.63-10.62,6.36-11.43,12.99c-0.18,1.48-0.19,2.99-0.19,4.49c0.01,61.81,0.01,123.62,0.06,185.43
      c0.01,8.82,3.65,14.32,10.75,16.75c12.03,4.12,22.79-3.54,23.16-16.98c0.38-13.65,0.17-27.32,0.19-40.98c0.02-13.81,0-27.62,0-42.01
      c-4.54,0-8.77,0-13.31,0c0-8.82,0-17.07,0-25.62c13.6,0,26.98,0,40.69,0c0.13,1.71,0.33,3.16,0.33,4.6
      c0.01,33.82-0.01,67.64-0.02,101.46c0,16.17-5.43,29.66-19.26,38.97c-22.46,15.12-54.68,7.66-65.78-15.82
      c-2.96-6.25-4.52-13.76-4.56-20.7c-0.35-62.31-0.3-124.62-0.17-186.93c0.05-23.55,15.02-39.05,39.69-41.79
      c23.62-2.63,44.14,10.63,48.52,32.64C277.4,43.16,276.86,53.79,277.52,64.79z"/>
    <path d="M0.19,199.85c9.47,0,18.23,0,27.85,0c0,10.74-0.28,21.21,0.08,31.66c0.36,10.47,7.53,16.81,17.92,16.6
      c7.23-0.15,13.17-4.74,14.94-11.74c0.57-2.25,1-4.6,1.01-6.9c0.07-23.49,0.04-46.97,0.04-70.74c-20.55-19.76-41.19-39.59-61.78-59.4
      C0.25,79-0.73,58.8,0.5,38.73C2.02,14.17,24.88-2.38,51.02,0.51c24.39,2.69,38.9,18.84,39.05,43.5c0.04,6.65,0.01,13.3,0.01,20.42
      c-9.25,0-18.12,0-27.85,0c0-6.79,0.02-13.71-0.01-20.62C62.16,30.22,51.77,22.8,38.85,27.15c-6.3,2.12-10.39,6.57-10.54,13.16
      c-0.36,15.47-0.12,30.95-0.12,46.95c20.38,19.24,41.06,38.77,61.4,57.98c0,29.85,1.04,59.21-0.3,88.45
      c-1.28,28.02-25.51,45.21-53.19,39.88c-20.27-3.91-34.79-19.99-35.82-40.52C-0.26,222.12,0.19,211.13,0.19,199.85z"/>
    <path d="M182.8,3.48c0,8.43,0,16.67,0,25.42c-10.05,0-19.79,0-30.16,0c0,81.39,0,162.27,0,243.58
      c-9.65,0-18.69,0-28.24,0c0-80.88,0-161.77,0-243.24c-10.2,0-19.92,0-29.96,0c0-8.84,0-17.09,0-25.76
      C123.81,3.48,153.16,3.48,182.8,3.48z"/>
  </svg>
)